import axios from 'axios';
import queryString from 'query-string';

export const endpoint = axios.create({
  baseURL: document.documentElement.getAttribute('data-api-endpoint'),
  headers: {},
  paramsSerializer: queryString.stringify,
});

// Axois's definition of an "absolute URL" is an URL with a hostname. This means
// "/api/2/foo" will be treated as a "relative" URL, so it will send
// "/api/2/api/2/foo". This makes little sense, so correct that with an
// interceptor.
endpoint.interceptors.request.use((config) => {
  if (config.url.startsWith('/'))
    config.baseURL = ''
  return config
})
