import React from 'react';
import { Classes } from '@blueprintjs/core';
import c from 'classnames';

const Summary = ({ className, text, truncate }) => {
  return (
    <div
      className={c(
        className,
        Classes.RUNNING_TEXT,
        Classes.TEXT_MUTED,
        'text-summary'
      )}
    >
      {!truncate && text}
    </div>
  );
};

export default Summary;
