import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Divider } from '@blueprintjs/core';

import { ClipboardInput } from 'components/common';

import './CollectionReference.scss';

class CollectionReference extends React.PureComponent {
  render() {
    const { collection } = this.props;

    return (
      <div className="CollectionReference">
        <div className="CollectionReference__section">
          <div className="key text-muted">
            <FormattedMessage
              id="collection.foreign_id"
              defaultMessage="Foreign ID"
            />
          </div>
          <div className="value">
            <ClipboardInput value={collection.foreign_id} />
          </div>
        </div>
        <Divider />
      </div>
    );
  }
}

export default injectIntl(CollectionReference);
